import React, { useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';

import { AuthContext } from '~contexts/auth';
import { UserContext } from '~contexts/user';

import IntakeActions from '~redux/intake';
import { getListingRedirect } from '~helpers/common';

import FullLoader from '~components/loader/full-loader';
import { getValidatedQueryString } from '~helpers/queryUtils';

export const getCurrentIdentityProvider = (sub) => {
  if (!sub) return null;
  const provider = sub.substr(0, sub.indexOf('|'));

  if (provider.indexOf('facebook') >= 0) {
    return 'facebook';
  }

  if (provider.indexOf('google') >= 0) {
    return 'google';
  }

  return null;
};

const AfterLogin = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const { user: authUser, isAuthenticated, signIn, isLoading } = useContext(AuthContext);
  const { user: backendUser, isLoading: isBackendUserLoading, isLoaded: isBackendUserLoaded } = useContext(UserContext);
  const intakeReferrer = useSelector((state) => state.intake.referrer);
  const provider = getCurrentIdentityProvider(authUser?.sub);

  const getExistingUserRedirectPath = () => {
    const referrer = queryString.parse(getValidatedQueryString(location.search)).referrer;

    if (referrer) {
      const [referrerPathname, referrerSearch] = decodeURIComponent(referrer).split('?');
      if (referrerPathname) {
        return referrerPathname + (referrerSearch || '');
      }
    }

    if (intakeReferrer) {
      const path = decodeURIComponent(intakeReferrer);
      dispatch(IntakeActions.setReferrer(null));
      return path;
    }

    if (!provider && !authUser.email_verified) {
      return '/verify/request';
    }

    const locationId =
      backendUser.accountType === 'worker'
        ? backendUser?.operations?.address?.place_id
        : backendUser?.wish?.address?.place_id || null;

    if(!locationId) {
      if(backendUser.accountType === 'worker') {
        return '/intake/worker';
      }
      return '/intake/client';
    }

    if (backendUser.accountType) {
      return getListingRedirect(backendUser.accountType, locationId);
    }

    return '/account';
  };

  const getNewUserRedirectPath = () => {
    if (provider) {
      return '/finalize/external';
    }

    if (!authUser.email_verified) {
      return '/verify/request';
    }

    return '/finalize/email';
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!isAuthenticated) {
      const search = queryString.parse(getValidatedQueryString(location.search)).error;
      if (search === 'access_denied') {
        history.push('/verify/request');
        return;
      }

      signIn();
      return;
    }

    if (isBackendUserLoaded) {
      const redirect = backendUser && backendUser.personId ? getExistingUserRedirectPath() : getNewUserRedirectPath();
      history.push(redirect);
    }
  }, [isLoading, isBackendUserLoaded, isBackendUserLoading]);

  return <FullLoader />;
};

export default AfterLogin;
